function formationQuiz() {
  const form = document.querySelector("#product-quiz");
  const loader = document.querySelector("#loader");
  const result = document.querySelector("#products-list");
  const redoQuizButton = document.querySelector("#redo-quiz");
  const prevButton = document.querySelector("#prev-question");

  let currentQuestion = 1;
  let lastQuestionBeforeSubmit = 1;
  let isFormSubmitted = false;

  const flow = {
    1: {
      technicienne: 2,
      non_technicienne: null,
    },
    2: {
      debutante: 3,
      confirmee: 3,
    },
    3: {
      monter_competence: null,
      lacunes: null,
    },
  };

  function resetCurrentRadioInputs(questionId, questions) {
    questions[questionId - 1]
      .querySelectorAll("input[type='radio']")
      .forEach((input) => {
        input.checked = false;
      });
  }

  function showQuestion(questionId, questions, reset = false) {
    questions.forEach(function (question) {
      question.classList.remove("active");
    });

    // If user click on redo to reset
    if (isFormSubmitted && result.innerHTML && reset === true) {
      questions[0].classList.add("active");
      prevButton.style.display = "none";
      return;
    }

    // If user clicks on the previous button after submitting the form : show last question before submit, hide result
    if (isFormSubmitted && result.innerHTML) {
      currentQuestion = lastQuestionBeforeSubmit;
      const lastQuestionElement = questions[lastQuestionBeforeSubmit - 1];
      lastQuestionElement.classList.add("active");
      form.style.display = "block";
      redoQuizButton.style.display = "none";
      result.innerHTML = "";
      resetCurrentRadioInputs(lastQuestionBeforeSubmit, questions);
      return;
    }

    currentQuestion = questionId;
    lastQuestionBeforeSubmit = questionId;
    const currentQuestionElement = questions[questionId - 1];
    currentQuestionElement.classList.add("active");
    resetCurrentRadioInputs(currentQuestion, questions);

    prevButton.style.display = currentQuestion > 1 ? "block" : "none";
  }

  function SubmitQuiz(form) {
    isFormSubmitted = true;
    const ajaxurl = form.getAttribute("action");
    const nonce = form.getAttribute("data-nonce");
    const formData = new FormData(form);
    prevButton.style.display = "none";

    if (loader) {
      loader.style.display = "block";
    }

    let data = {
      action: "get_matching_products",
      nonce: nonce,
      typeFormation: formData.get("question_technicienne_type"),
      levelFormation: formData.get("question_level"),
      objectifFormation: formData.get("question_objectif"),
    };

    fetch(ajaxurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
      },
      body: new URLSearchParams(data),
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (response) {
        let responseData;
        try {
          responseData = JSON.parse(response);
          result.innerHTML = responseData.html;
        } catch (error) {
          console.error("Error parsing JSON:", error);
          result.innerHTML = __(
            "Une erreur est survenue lors de la récupération des produits.",
            "sage"
          );
        }
        loader.style.display = "none";
        redoQuizButton.style.display = "block";
        prevButton.style.display = "block";
      })
      .catch((error) => {
        console.error("Error AJAX:", error);
        loader.style.display = "none";
      });
  }

  if (redoQuizButton) {
    redoQuizButton.addEventListener("click", function () {
      form.style.display = "block";
      form.reset();
      showQuestion(1, form.querySelectorAll(".question"), true);
      result.innerHTML = "";
      redoQuizButton.style.display = "none";
    });
  }

  if (form) {
    form.reset();
    const questions = form.querySelectorAll(".question");
    const submit = form.querySelector("button[type='submit']");
    showQuestion(1, questions); // Show first question

    form.addEventListener("change", function (event) {
      const question = event.target.parentElement.parentElement;
      const questionId = question.getAttribute("data-question");
      const answer = event.target.value;
      let nextQuestionId = null;

      if (flow[questionId] && flow[questionId][answer]) {
        nextQuestionId = flow[questionId][answer];
        showQuestion(nextQuestionId, questions);
      }

      if (nextQuestionId === null) {
        form.style.display = "none";
        new SubmitQuiz(form);
      } else {
        submit.style.display = "none";
      }
    });

    prevButton.addEventListener("click", function () {
      showQuestion(currentQuestion - 1, questions);
    });
  }
}
