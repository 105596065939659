function formationQuiz() {
  const form = document.querySelector("#product-quiz");
  const loader = document.querySelector("#loader");
  const result = document.querySelector("#products-list");
  const redoQuizButton = document.querySelector("#redo-quiz");

  const flow = {
    1: {
      technicienne: 2,
      non_technicienne: null,
    },
    2: {
      debutante: 3,
      confirmee: 3,
    },
    3: {
      monter_competence: null,
      lacunes: null,
    },
  };

  function showQuestion(questionId, questions) {
    questions.forEach(function (question) {
      // question.style.display = "none";
      question.classList.remove("active");
    });

    // questions[questionId - 1].style.display = "flex";
    questions[questionId - 1].classList.add("active");
    currentQuestion = questionId;
  }

  function SubmitQuiz(form) {
    const ajaxurl = form.getAttribute("action");
    const nonce = form.getAttribute("data-nonce");
    const formData = new FormData(form);

    if (loader) {
      loader.style.display = "block";
    }

    let data = {
      action: "get_matching_products",
      nonce: nonce,
      typeFormation: formData.get("question_technicienne_type"),
      levelFormation: formData.get("question_level"),
      objectifFormation: formData.get("question_objectif"),
    };

    fetch(ajaxurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
      },
      body: new URLSearchParams(data),
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (response) {
        const responseData = JSON.parse(response);
        result.innerHTML = responseData.html;
        loader.style.display = "none";
        redoQuizButton.style.display = "block";
      })
      .catch((error) => {
        console.error("Error AJAX:", error);
        loader.style.display = "none";
      });
  }

  if (redoQuizButton) {
    redoQuizButton.addEventListener("click", function () {
      form.style.display = "block";
      form.reset();
      showQuestion(1, form.querySelectorAll(".question"));
      result.innerHTML = "";
      redoQuizButton.style.display = "none";
    });
  }

  if (form) {
    form.reset();
    const questions = form.querySelectorAll(".question");
    const submit = form.querySelector("button[type='submit']");
    showQuestion(1, questions); // Show first question

    form.addEventListener("change", function (event) {
      const question = event.target.parentElement.parentElement;
      const questionId = question.getAttribute("data-question");
      const answer = event.target.value;
      let nextQuestionId = null;

      if (flow[questionId] && flow[questionId][answer]) {
        nextQuestionId = flow[questionId][answer];
        showQuestion(nextQuestionId, questions);
      }

      if (nextQuestionId === null) {
        form.style.display = "none";
        new SubmitQuiz(form);
      } else {
        submit.style.display = "none";
      }
    });
  }
}
